<template>
  <div id="order_page" class="box" v-if="loaded">
    <template v-if="paymentId === 0">
        <h1>פרטי ההזמנה</h1>
        <ul class="details">
            <li><i class="fas fa-table"></i><span v-text="date_parsed"></span></li>
            <li><i class="fas fa-map-marker-alt"></i><span v-text="mikve.city.name"></span> - <span v-text="mikve.address"></span></li>
            <li><i class="fas fa-scroll"></i><span v-text="orderType"></span></li>
            <li v-if="mikve.note && mikve.note !=''"><i class="fas fa-exclamation"></i><div class="inner_text" v-html="mikve.note"></div></li>
        </ul>
        <div style="display:flex;justify-content: center;" v-if="editID">
            <button class="btn" @click.once="order('payme')"><i class="fas fa-check-circle"></i>ביצוע שינוי</button>
        </div>
        <div v-else-if="today_free">
             <div class="packages" style="text-align: center;">
                <div v-text="get_word('free_day_text')"></div>
                <button class="btn" @click.once="order('free')">הזמנת תור</button>
            </div> 
        </div>
        <div v-else-if="curr_package == false">
            <div class="packages" v-if="(typeData.is_free_bride == '0' && get_me().is_bride_valid) || (!get_me().is_bride_valid)">
                <ul>
                    <li :class="{active: !optSelected}" @click="optSelected=false">
                        <h4> כניסה בודדת</h4>
                        <span v-if="mikve.is_apt == '0'" v-text="'₪' + total"></span>
                    </li>
                    <template v-if="packages && packages.length > 0">
                        <li v-for="pack in packages" :key="pack.id" @click="optSelected=pack.id" :class="{active: optSelected==pack.id}">
                            <h4 v-text="pack.name"></h4>
                            <span v-text="'₪' + pack.price"></span>
                        </li>
                    </template>
                </ul>
                <div id="more_prods" v-if="mikve.is_apt == '0' && shop && !isEmpty(shop)">
                    <div class="inner_title small" >
                        מוצרים נוספים:
                    </div>
                    <div class="items">
                        <div class="item" v-for="item in shop" :key="item.id">
                            <div class="content">
                                <h3 >{{item.name }} - {{ item.price }}₪</h3>
                            </div>
                            <div class="number_inp">
                                <a @click="add(item)" :class="{disabled: item.qty >= item.max_qty}"><i class="fas fa-plus-circle"></i></a>
                                <span v-text="item.qty"></span>
                                <a @click="remove(item)"><i class="fas fa-minus-circle"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inner_title" v-if="mikve.is_apt == '0'">
                    לתשלום: ₪<span v-text="totalComp"></span>
                </div>
                <div class="btns_wrapper">
                    <div v-if="is_allow_bit">
                        <button class="btn" @click="order('bit')"><img src="../assets/Bit_logo.svg" style="height:25px" alt="" /></button>
                        <div>{{ get_word('pay_bit') }}</div>
                    </div>
                      <div v-if="get_me().four_digits != '' && get_me().charge_token != '' && mikve.is_apt == '0'" style="text-align: center;">
                    <!-- <div v-html="get_word('order_before')"></div> -->
                        <button class="btn_white" @click.once="order('token')" ><i class="fas fa-id-card"></i></button>
                        <div>{{ get_word('pay_with_token') }}{{ get_me().four_digits }}</div>
                    </div>
                    <div v-if="mikve.is_apt == '0'">
                        <button class="btn" @click.once="order('payme')"><i class="far fa-credit-card"></i></button>
                        <div>{{ get_word('pay_credit') }}</div>
                    </div>
                    <div v-else style="margin:10px 0">
                        **במקווה זה אין אפשרות לשלם אונליין
                    </div>
                    
                </div>
                <div style="text-align: center;" v-if="!optSelected && show_cash_btn">
                    <a  v-if="mikve.is_apt == '0'" style="text-decoration: underline; font-size: 16px;"  @click.once="order('cash')">אשלם במקווה</a>
                    <a  v-else class="btn" @click.once="order('free')">אישור הזמנה</a>
                </div>
            </div>
            <div class="packages" v-if="typeData.is_free_bride == '1' && get_me().is_bride_valid" style="text-align: center;">
                <div v-text="get_word('bride_free')"></div>
                <button class="btn" @click.once="order('free')">הזמנת תור</button>
            </div>
        </div>
        <div v-else>
            <h3>יש לך כרטיסייה</h3>
            <div>השתמשת ב<span v-text="curr_package.uses + '/' + curr_package.num_apt"></span></div>
            <div id="more_prods" v-if="mikve.is_apt == '0' && shop && !isEmpty(shop)">
                <div class="inner_title small" >
                    מוצרים נוספים:
                </div>
                <div class="items">
                    <div class="item" v-for="item in shop" :key="item.id">
                        <div class="content">
                            <h3 >{{item.name }} - {{ item.price }}₪</h3>
                        </div>
                        <div class="number_inp">
                            <a @click="add(item)" :class="{disabled: item.qty >= item.max_qty}"><i class="fas fa-plus-circle"></i></a>
                            <span v-text="item.qty"></span>
                            <a @click="remove(item)"><i class="fas fa-minus-circle"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inner_title small" v-if="totalShop > 0">
                לתשלום: ₪<span v-text="totalShop"></span>
                 <div class="btns_wrapper">
                      <div v-if="get_me().four_digits != '' && get_me().charge_token != '' && mikve.is_apt == '0'" style="text-align: center;">
                        <button class="btn_white" @click.once="order('token')" ><i class="fas fa-id-card"></i></button>
                        <div>{{ get_word('pay_with_token') }}{{ get_me().four_digits }}</div>
                    </div>
                    <div v-if="mikve.is_apt == '0'">
                        <button class="btn" @click.once="order('payme')"><i class="far fa-credit-card"></i></button>
                        <div>{{ get_word('pay_credit') }}</div>
                    </div>
                    <div v-else style="margin:10px 0">
                        **במקווה זה אין אפשרות לשלם אונליין
                    </div>
                </div>
                <a  v-if="mikve.is_apt == '0' && show_cash_btn" style="text-decoration: underline; font-size: 16px;"  @click.once="order('cash')">אשלם במקווה</a>
            </div>
            <button v-else class="btn" @click.once="order('payme')"><i class="fas fa-check-circle"></i>ביצוע הזמנה</button> 
        </div>
    </template>
    <template v-else-if="(paymentId > 0 &&  payment_url == '')">
        <h1>תשלום</h1>
        <payment :paymentData="paymentData" @payment_success="handlePaymentSuccess"  @fail_msg="failMsg"/>
    </template>
    <template v-else-if="(paymentId > 0 && payment_url != '')">
        <h1>תשלום</h1>
        <iframe height="300" :src="payment_url" width="100%" frameborder="0" ></iframe>
    </template>
  </div>
</template>

<script>
import payment from "@/components/payment.vue";
export default {
    components:{payment},
    data() {
        return {
            editID: this.isNull(this.$route.params.id) ? false: this.$route.params.id,
            mikveID: this.$route.params.mikve_id,
            roomID: this.$route.params.room_id,
            today_free: false,
            show_cash_btn: true,
            is_allow_bit: false,
            mikve: false,
            paymentData:{},
            apt_loading: false,
            date: this.$route.params.date,
            type: this.$route.params.type.split("+")[0],
            typeData: {},
            paymentId: 0,
            loaded: false,
            paymentMethod: false,
            packages: [],
            shop: [],
            total: 0,
            payment_url: '',
            optSelected: false,
            curr_package: false,
            date_parsed: false,
        }
    },
    mounted(){ 
  
      this.api({action: 'get_preorder_data', data: { mikve_id: this.mikveID, type: this.type, date: this.date ,room_id: this.roomID } }, (data) => {
        if(data.data.is_exist_order && (!this.editID > 0))
        {
            this.alert('כבר קיימת הזמנה לתאריך זה , לא ניתן להזמין טבילה חדשה. במידה ותרצי ניתן לשנות את תאריך הטבילה בדף הבית');
            this.$router.replace({ name: 'Home' });
        }
        this.mikve = data.data.mikve;
        this.is_allow_bit = (data.data.is_allow_bit == "1") ? true : false;
        this.show_cash_btn = (data.data.show_cash_btn == "1") ? true : false;
        this.today_free = data.data.today_free;
        this.typeData = data.data.type;

       
         data.data.shop.map((item) => {
                item.in_basket = false;
                item.qty = 0;
                return item;
            });
        this.shop = data.data.shop;


        this.date_parsed = data.data.date_parsed;
        this.total = data.data.total;
        this.packages = data.data.packages;
        this.curr_package = data.data.curr_package;


        this.loaded = true;
      });
      


    },
    computed: {
        orderType(){
             let types = JSON.parse(localStorage.getItem('order_types'));

            for (let i in types)
            {
                if (this.type == types[i].id) return types[i].name;
            }

            return '';
        },
        orderNumberType(){
            let types = JSON.parse(localStorage.getItem('order_types'));

            for (let i in types)
            {
                if (this.type == types[i].id) return types[i].type;
            }

            return 1;
        },
        totalShop(){

            let store_ttl = 0;

            for (let i in this.shop)
            {
                if (this.shop[i].qty > 0)
                {
                    store_ttl += (+this.shop[i].qty * +this.shop[i].price);
                }
            }

            return store_ttl;
        },
        totalComp(){

            if (this.optSelected === false) {
                return +this.total + +this.totalShop;
            }
            else 
            {
                for (let i in this.packages)
                {
                    if (this.packages[i].id == this.optSelected) return +this.packages[i].price + +this.totalShop;
                }
            }

            return 0;
        }
    },
    methods:{
        failMsg(){
            this.alert(this.get_word('cant_create_payment'));
            this.paymentId = 0;
        },
        handlePaymentSuccess(order_id){
            let link = '/success_page/'+order_id;
            this.$router.push(link);
        },
         add(item){
            if (item.max_qty > item.qty) item.qty++;
        },
        remove(item){
            if (item.qty == 0) 
            {
                item.qty = 0;
            }
            else item.qty--;
        },
        order(provider = 'payme'){

            if(this.apt_loading){
                return false
            }
            this.apt_loading = true;

            let show_cart = [];

            for (let i in this.shop)
            {
                if (this.shop[i].qty > 0) show_cart.push({qty:this.shop[i].qty ,id:this.shop[i].id});
            }

            let data = { edit_id: this.editID, mikve_id: this.mikve.id, date: this.date, type: this.type, provider: provider, package_id: this.optSelected,room_id:this.roomID };

            if (this.curr_package) data.package_order_id = this.curr_package.id;

            if (show_cart.length > 0) data.shop = show_cart;
            

            

            if((this.orderNumberType == 2 || this.orderNumberType == 3) && this.$route.params.type.split("+")[1] && this.$route.params.type.split("+")[1] != ""){
                data.apt_length = this.$route.params.type.split("+")[1];
            } 

            this.api({ action: 'add_apt_new', data: data}, (data)=>{
                if (data.data) {
                    if (data.data.status === true)
                    {
                        let link = '/success_page/'+data.data.order_id;
                        this.apt_loading = false;
                        this.$router.push(link);
                        
                    }
                    else if (data.data.status && data.data.status == "failed")
                    {
                       this.alert("חיוב נכשל, נא לנסות שנית");   
                       this.apt_loading = false;
                    }
                    else 
                    {
                        this.paymentData = {
                            name: data.data.name,
                            price: +data.data.amount + '₪',
                            id:data.data.id,
                        };
                        this.paymentId = data.data.id;
                        if(provider == 'bit')
                        {
                            this.payment_url = data.data.payment_url;
                        }
                        this.apt_loading = false;
                    }
                }
            });
        },

        payWithToken(){
            this.api({ action: 'payWithToken'}, (data)=>{
                if(data.data)
                {
                    this.alert("הזמנה בוצעה בהצלחה", null, 'success');
                }
                else{
                    this.alert("תשלום נכשל אנא נסי אמצעי תשלום אחר", null, 'error');
                }
                
            })
        },
    }
}
</script>

<style lang="scss">
.inner_text{ 
    a{color: blue;font-weight: 700;text-decoration: underline;}
}
#order_page {
    #more_prods{
        .items{display: flex;flex-direction: column;}
        .item{display: flex;justify-content:space-between;padding:10px 0;
          h3{font-size: 17px;color: #000;font-weight: 500;} 
          .description,.price{font-size: 16px;}
          .btn{font-size: 16px;height: auto;}
        }
        .number_inp{display: flex; color:var(--color_dom); gap: 4px; align-items: center;
            a{color:  var(--color_dom)}
        }
    }
    
    &{padding-top: 40px;}
    h1{text-align: center;margin-bottom: 10px;}
   
    .details { list-style: none; margin-bottom: 15px;
        li { display: flex; gap:10px; align-items: center;
            i, svg { color: var(--color1); width: 20px; }
            label { display: block; font-weight: bold;
                &::after { content: ":"; }
            }
            span { font-weight: 400;}
        }
    }

    .inner_title { font-size: 25px; text-align: center; font-weight: bold; margin: 15px 0;}
    .small{font-size: 18px;margin-bottom: 0px;text-align: right;}
    .btns_wrapper{display: flex;justify-content: center;gap: 20px; gap:10px;
        & > div { flex:1; text-align: center;
            button { width:100%; font-size: 26px;}
            div { font-size: 16px;}
        }
    }
    iframe{width: 100%;border: none;outline: none; }

    .packages { 

        ul { list-style: none; border-radius: 10px; overflow: hidden;
            li { cursor: pointer; padding: 10px; background: #eee; border-bottom: 1px solid rgba(0,0,0,0.3); display: flex; justify-content: space-between; gap:15px; align-items: center;
                &.active { background: var(--color_dom); color:#fff;}
                h4 { font-weight: normal;}
                span { font-weight: bold;}
            }
        }
    }
}
</style>