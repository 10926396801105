<template>
    <div class="form">
        <h2 class="title" v-html="get_word('insert_otp')"></h2>
        <form class="form_steps" @submit.prevent="validateOtp()">
            <div class="form-item">
                <label for="otp" v-html="get_word('my_otp_is')"></label>
                <input inputmode="numeric" type="number" class="styled_input" id="otp" v-model="otp" autocomplete="one-time-code" required/>
            </div>
            <div class="btn_wrapper">
                <!-- <button type="button" class="btn_primary clean_btn" :disabled="allowCodeSend" @click="startCountdown">
                  <vue-countdown v-if="allowCodeSend" :time="3000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ get_word('send_more_code') }} {{ totalSeconds }}</vue-countdown>
                  <span v-else >{{get_word('send_code')}}</span>
                </button> -->
                <input type="submit" class="btn_primary clean_btn" :value="get_word('proceed')">
            </div>
        </form>
    </div>
</template>
<script>
// import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  // components: { VueCountdown },
  props: {
    
    phoneNumber: {
      required: true,
      type: String,
    },
    
  },
    data() {
        return {
            allowCodeSend:true,
            otp:'',
        }
    },
    
    mounted(){
      if(this.isIOS && this.isApp)
      {
          this.getOTP();
      }
        
    },
    methods:{
       async getOTP() {
    
        if ('OTPCredential' in window) {
          try {
            // User clicks on the input field or a button to trigger the OTP request
            const content = await navigator.credentials.get({
              otp: { transport: ['sms'] }
            });
            

            // Automatically fill the input field with the OTP
            if(content && content.code) this.otp = content.code;
            
            // Optionally, you can submit the form or verify the OTP here
          } catch (error) {
          console.log(error);
          }
        } 
      },
       afterLogin(){
        var self = this;

        if (!this.isIOS)
        {
          var permissions = cordova.plugins.permissions;
          permissions.requestPermission(permissions.POST_NOTIFICATIONS, () => {
            
              window.cordova.plugins.firebase.messaging.subscribe("main");
          
              window.cordova.plugins.firebase.messaging.getToken().then(function(token) {
                
                self.api({ 'action': 'set_push_token', data: {'token': token}}, function(){
                
                });
              });
          });
        }
        else if(this.isIOS && this.isApp){
          window.cordova.plugins.firebase.messaging.requestPermission({forceShow: true}).then(() => {
            //just for test
            // window.cordova.plugins.firebase.messaging.subscribe("test");
            window.cordova.plugins.firebase.messaging.subscribe("main");
          
              window.cordova.plugins.firebase.messaging.getToken().then(function(token) {
                
                self.api({ 'action': 'set_push_token', data: {'token': token}}, function(){
                
                });
              });
            });
        }
      },
      startCountdown(){
        this.$emit('sendOtp');
        this.allowCodeSend = true;
      },
      onCountdownEnd() {
        this.allowCodeSend = false;
      },
      validateOtp(){

        var phone = '';
        this.allow_code_send = false;
        phone = this.phoneNumber;
        
        
        this.api({ action: 'validate_otp', data:{ identification:phone ,otp: this.otp }}, (data) => {
            if(data.data === false) {
              this.alert(this.get_word('wrong_code'));
            }	else {
                this.set_user_token(data.data);
                if (this.isApp) this.afterLogin();
                this.$bus.$emit('reload');
            }
        });
      },
    }
}
</script>