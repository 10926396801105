<template>
<div id="inbox_page">
	<div class="centered-big" v-if="mikveData">
			<div class="chat_area_wrapper" id="chat_area_wrapper">
					<div class="chat_wrapper">
						<Chat :user_data="mikveData" ref="chatbox" />
					</div>
			</div>
	</div>
</div>
</template>

<script>
// @ is an alias to /src
//import Threads from '@/components/inbox/threads.vue'
//import ChatUserDetails from '@/components/inbox/user_details.vue'
import Chat from "@/components/chat.vue";

export default {
	props: ["menu"],
	components: { Chat },
	data() {
		return {
			mikveData: false
		};
	},

	computed: {
	},

	methods: {
	},
  unmounted(){
    document.body.classList.remove('moveEnable');
  },
	mounted() {   
    document.body.classList.add('moveEnable');
  
		this.api({action: 'get_mikve_info',data: { mikve_id: this.$route.params.id } }, (data)=>{
			this.mikveData = data.data;
		});

	},
};
</script>

<style lang="scss">
#inbox_page {
  
  .md-layout {
    &{display: flex; gap: 50px; height:calc(100vh - 130px - 30px); }
    .threads_wrapper{flex:1;z-index: 9; 
      @media (min-width: 600px) { width:30%;}
    }
    .chat_area_wrapper{flex:3;max-height: calc(100vh - 130px - 30px);overflow: auto;}
    .chat_user_details_wrapper{ display: flex; width: 100%; background:#354E68; min-height: 90px; gap: 10px; padding:0 10px; align-items: center; border-radius: 20px; color: #fff;
      .details { display: flex; gap: 10px; align-items: center; 
        .user_icon {
           @media (min-width: 600px) { width: 70px;height: 70px; }
           @media (max-width: 600px) { width: 50px;height: 50px; }
        }
        .name {  font-size: 22px !important; font-weight: bold;}
        h5 { font-weight: normal; font-size: 18px; white-space: nowrap; overflow: hidden; }
      }
      .options_line { text-align:left; flex:1; }
    }
  }

  .threads_wrapper { color:#333; }
  #chat_area_wrapper { background:#f1f1f3;  border-radius: 20px;
    .sc-chat-window { height: calc(100vh - 130px - 90px - 30px) !important; max-height: 100% !important; position: static; background:none;
        @media (max-width: 600px) {  height: calc(100vh - 70px - 70px - 70px) !important; }
    }
    .sc-message-list { background: none !important; } 
  }
  
  .sc-message { width: auto  !important;}
  @media (max-width: 600px) {
    .side_menu_closed_emit{position: fixed;width: 100%;height: 100%;z-index: 9;}
    .chat_user_details_wrapper{display: none; min-height: 70px !important; }
 
    .chat_area_wrapper{overflow: hidden !important; }
      /*{max-height: 100vh;overflow: hidden;}*/
      .centered-big{max-width: 100% !important;}
      #chat_user_details_wrapper{position: relative;
    z-index: 1;min-width: 100%;max-width: 100%; }

      #threads .list li .msg{ font-size: 15px; }
      .user_icon.size_sml{width: 50px;height: 50px; border-radius: 100%;}
      .md-layout{ max-height: initial; overflow: hidden; /*position: fixed; */width: 100%; display: block; height: auto; }
      .md-layout-item.md-size-50{min-width: 70%;}
      #threads{max-height: initial;overflow-y: initial; }

    .chat_user_details_wrapper { flex-wrap: wrap; padding: 15px !important;
      .user_icon.size_sml { width: 40px; height: 40px;}
      .options_line { text-align:center !important; }
    }

    .dt_btn_icon,.dt_btn { font-size: 16px; padding: 0 8px; min-width: 35px; justify-content: center;}

    .sc-message-list { height: 100% !important;}
  }
}

/* THREADS */
#nav-icon1{height: 30px;margin: 3px ;width: 35px;}
 #nav-icon1 span{color: #fff !important;}

  .side_menu_opend #menu_hamburger #nav-icon1 span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .side_menu_opend {
    .fa-chevron-left{display: none;}
  }
  .side_menu_closed{
    &{transition: .3s all ease-in-out}
    .user_icon.size_sml{width: 70px; height: 70px;}
    #threads .list li{padding: 12px;}
    .fa-chevron-right{display: none;}
  }
.side_menu{
      &{transition: .3s all ease-in-out}
  #menu_hamburger{position: absolute;
    left: -29px;
    right: auto;
    background: #4c4c4c;
    width: 30px;
    line-height: 32px;
    height: 30px;text-align: center;}
 
}

.therd{position: relative;}
#threads {
    &{max-height: calc(100vh - 130px - 30px - 42px);overflow: auto;
        @media only screen and (max-width: 600px) { max-height: calc(100vh - 60px); }
    }
    .list { 
        li { 
			&{margin:20px 0;display: block; cursor: pointer; overflow: hidden; padding: 15px; border-radius: 20px; position: relative; background: #f1f1f3;}
            &.active { background: rgb(78 140 255 / 25%); }
            &.disabled { opacity: 0.7; cursor: default;}
            &.to_read { 
                .num { position: absolute; position: absolute; background: red; color: #fff; border-radius: 100%; text-align:center; line-height:20px; font-size: 15px;; width: 20px; height: 20px; right: 10px; top: 10px; z-index: 2; }
             }

            .thread_msg_wrapper { display: flex; gap:10px;}

            // .user_icon  { }
            .name { display: block; font-weight: bold; color: #354E68;}
            .msg { display: block; font-size: 15px; color: #999; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 90%; max-width: 70vw;}
            .date { display: block; font-size: 15px; color: #999; }
        }
    }
}

#thread_filter { display: flex; border:1px solid var(--color);  border-radius: 20px; overflow: hidden;
  a { line-height: 40px; text-align: center; flex:1; color: var(--color); font-size: 16px;
    &.active { background: var(--color); color:#fff;}
  }
}
</style>