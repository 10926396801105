<template>
    <div id="contact_page" style="padding:0 15px;">
        <div class="icon"><i class="fas fa-headset"></i></div>
        <h2 v-html="get_word('comtact_title')"></h2>
        <ul>
            <li>
                <a target="_blank" href="mailto:noam@mikve.app">
                    <div class="icon_wrapper"><i class="far fa-envelope"></i></div>
                    <div class="text">
                        <div class="main"  v-html="get_word('mail_title')"></div>
                        <div class="small_text" v-html="get_word('mail_text')"></div>
                    </div>
                </a>
            </li>
            <!-- <li>
                <a target="_blank" href="https://api.whatsapp.com/send?phone=+972545468863&text=שלום אשמח לעזרה">
                    <div class="icon_wrapper"><i class="fab fa-whatsapp"></i></div>
                    <div class="text">
                        <div class="main"  v-html="get_word('whatsapp_title')"></div>
                        <div class="small_text" v-html="get_word('whatsapp_text')"></div>
                    </div>
                </a>
            </li> -->
        </ul>
    </div>
</template>
<script>


export default {
    data(){
        return {
       
        }
    },
 

}

</script>
<style lang="scss" scoped>
.icon{font-size: 100px;text-align: center;color:#127dbb;}
h2{text-align: center;padding: 0px 0 10px;font-size: 30px;}
ul {
    li{
        a{display: flex;gap: 15px;align-items: center;font-weight: 600;
            .icon_wrapper{font-size: 25px;color:#127dbb;}
            .small_text{font-weight: 200;font-size: 15px;}
        }
    }
}
</style>