<template>
  <div class="chat_wrapper_div" >
    <h1>{{ user_data.city }} - {{user_data.address}} </h1>
    <div class="connect" >{{connect_text}}</div>
    <Chat v-if="loaded"
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="true"
      :showFile="false"
      :showEdition="true"
      :showDeletion="true"
      :hideUserInput="hideUserInput"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="false"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      :placeholder="get_word('write_message')"
      @onType="handleOnType"
      @edit="editMessage">
      <!-- <template v-slot:header><UserIcon size="sml" :user_data="user_data" /> {{participants.map(m=>m.name).join(' & ')}}</template> -->
</Chat>
    <div v-if="!loaded" class="loading_msgs">טוען הודעות..</div>
  </div>
</template>


<script>

import Chat from './vue-beautifull-chat/Launcher.vue'; //vue-beautiful-chat/index.js';

//import UserIcon from './user_icon.vue';
 
export default {
    props: ["user_data"],
  components: { Chat },
  data() {
    return {
        loaded: false,
        interval: '',
        connect: false,
        hideUserInput: false,
      messageList: [
          /*
          { type: 'text', author: `me`, data: { text: `Say yes!` } },
          { type: 'text', author: `user1`, data: { text: `No.` } }
          */
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#0a1130',
          text: '#ffffff'
        },
        launcher: {
          bg: '#1e2732'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#000'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },
  computed:{
      mikve_id(){
        return this.user_data.id;
      },
      connect_text(){
        return (this.connect ? "מחוברת" : "");
      },
       participants(){
           if (this.user_data == false) return [];
           return [{
                id: this.mikve_id,
                name: this.user_data.display_name,
                imageUrl: this.user_data.pic_parsed
                }];
        }, // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
        titleImageUrl(){
            if (this.user_data != false) return this.user_data.pic_parsed; 
            return '';
        }
  },
  methods: {
    /*
    sendMessage (text) { 
      if (text.length > 0)
      {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    },
    */
    onMsgInner(data)
    {
      if (data.from == this.user_data.id || data.to == this.user_data.id)
      {
        this.pushMessage({
          author: data.from,
          readed:false, 
          date: '', 
          type: 'text', 
          data: { text: data.text },
          via: ''
        });
      }
    },

    onMessageWasSent (message) {
      // called when the user sends a message
        let msg = (message.type == 'emoji' ? message.data.emoji : message.data.text);
        // categories 
        this.api({ action: 'chat/send', data: { chat_code: this.mikve_id + '_' + this.get_me().id , msg: msg } }, () => { 
          
          // this.$emit('message_sent', this.mikve_id, msg);
          this.load_data();
            
        } );
    },
    pushMessage(message)
    {
      //console.log(this.user_data);
     // console.log(message.author);
      message.author = (this.get_me().id == message.author ? 'me': message.author);
      // console.log(this.user_data.id)
      // console.log(message)
      this.messageList = [ ...this.messageList, message ];
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
      this.$emit('close');
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      // console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    load_data(){
       this.messageList = [];
      this.api({ action: 'chat/getMessages_init', data: { chat_code: this.user_data.id + '_' + this.get_me().id } }, (data) => {

      if (this.isNull(data.data)) { this.loaded = true; return; }
          
        if(data.data.unreaded > 0 )
        {
          this.$emit('chat_change');
        } 
        for (let i in data.data.msgs)
        {
            let msg = data.data.msgs[i];
            
            this.pushMessage({ author: msg.uid_from, readed:msg.readed, date: msg.date, type: 'text', data: { text: msg.msg }, via: msg.via });
        }

        this.connect = data.data.connect;
        this.loaded = true;

      }, () => {
        this.alert('הטעינה נכשלה. אנא נסה שנית');
      } );
    }
  },
  mounted(){
// console.log(this.user_data)
      this.load_data();

     this.interval = setInterval(()=>{
        this.load_data();
      },5000);
      
  },
  beforeUnmount(){
    clearInterval(this.interval);
      // this.$eventBus.$off('new-msg-inner', this.onMsgInner);
  }
}

</script>
<style scoped>
.chat_wrapper_div{padding: 10px 0 0 0 ;}
.dot{height: 20px;width: 20px;border-radius: 100%;background: #50dd50;position: absolute;left: 0;top: 0;}
.connect{font-size: 15px; padding-right: 10px; margin-top: -10px; margin-bottom: 10px;}
h1{margin: 10px;position: relative;padding-bottom: 10px;}
.wait_for_accept {text-align: center; padding:25px; columns: #000; font-size: 18px; background: #f1f1f3; overflow-y: auto;}
.loading_msgs { display: flex; justify-content: center; align-items: center; font-weight: bold; padding: 25px 15px; }
</style>