import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Pages from '../views/pages.vue'
import contact from '../views/contact.vue'
import login from '../views/login.vue'
import mikve_info from '../views/mikve_info.vue'
import OrderPage from '../views/OrderPage.vue'
import Edit from '../views/edit.vue'
import SelectedDayPage from '../views/SelectedDayPage.vue'
import UserSettings from '@/views/UserSettings.vue'
import mikveList from '@/views/mikveList.vue'
import Inbox from '@/views/inbox.vue'
import lastorders from '@/views/lastorders.vue'
import Shop from '@/views/shop.vue'
import debt from '@/views/debt.vue'
import terms from '@/views/terms.vue'
import successPage from '@/views/thankPage.vue'
import privacy from '@/views/privacy.vue'


const routes = [
  { path: '', name: 'Home', component: Home, props: { footer: true, header: true, footerArticles: false, hide_back: true} },
  { path: '/', name: 'Home', component: Home, props: { footer: true, header: true, footerArticles: false, hide_back: true} },
  { path: '/index.html', redirect: '/' },
  { path: '/success_page/:id', component: successPage, props: { footer: true, header: true, footerArticles: false} },
  { path: '/privacy', component: privacy, props: { footer: true, header: true, footerArticles: false} },
  { path: '/terms', component: terms, props: { footer: true, header: true, footerArticles: false} },
  { path: '/debt', component: debt, props: { footer: true, header: true, footerArticles: false} },
  { path: '/lastorders', component: lastorders, props: { footer: true, header: true, footerArticles: false} },
  { path: '/about', component: Pages, props: { footer: true, header: true, footerArticles: false} },
  { path: '/mikve_info', component: mikve_info, props: { footer: true, header: true, footerArticles: false} },
  { path: '/mikveList', component: mikveList, props: { footer: true, header: true, footerArticles: false} },
  { path: '/order/:mikve_id/:date/:type/:room_id', name:"order1", component: OrderPage, props: { footer: true, header: true, footerArticles: false} },
  { path: '/order/:id/:mikve_id/:date/:type/:room_id', name:"order2",component: OrderPage, props: { footer: true, header: true, footerArticles: false} },
  { path: '/edit/:id', component: Edit, props: { footer: true, header: true, footerArticles: false} },
  { path: '/edit/:id/:mikve_id/:date/:type', component: SelectedDayPage, props: { footer: true, header: true, footerArticles: false} },
  { path: '/contact', component: contact, props: { footer: true, header: true, footerArticles: false} },
  { path: '/inbox/:id', component: Inbox, props: { footer: true, header: true, footerArticles: false} },
  { path: '/login', component: login, props: { footer: true, header: true, footerArticles: false} },
  { path: '/mikve/:mikve_id/:date/:type', component: SelectedDayPage, props: { footer: true, header: true, footerArticles: false} },
  { path: '/settings', component: UserSettings, props: { footer: true, header: true, footerArticles: false} },
  { path: '/shop/:orderID', component: Shop, props: { footer: true, header: true, footerArticles: false} },
  { path: "/:catchAll(.*)", name:'404', component: NotFound, props: { footer: true, header: true, footerArticles: true } },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
 
  scrollBehavior(/*to, from, savedPosition*/) {
    return new Promise((resolve/*, reject*/) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 200)
    })
  },
})

export default router
