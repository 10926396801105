<template>
  <div class="paddme">
    <div id="prev_orders"  v-if="loaded && orders.length > 0" style="margin-bottom: 20px;">
        <div class="line"> 
            <div class="content">
            <div  class="panel" v-if="orders && !isEmpty(orders)">
              <h2>{{get_word('my_last_orders')}} </h2>
              <ul class="box" >
                  <li v-for="item in orders" :key="item.id">
                      <div class="ord_wrapper">
                          <div class="text">
                            <div> <b>{{item.mikve_data.city}} - {{item.mikve_data.address}}</b></div>
                            <span>{{item.apt_time}} </span>
                            <a v-if="item.invoice && item.invoice !=''" :href="item.invoice" target="_blank" class="btn">קבלה</a>
                          </div>
                      </div>
                  </li>
              </ul>
            </div>
             <div v-if="packs_orders && !isEmpty(packs_orders)" class="panel">
              <h2>{{get_word('my_last_packs')}} </h2>
              <ul class="box" >
                  <li v-for="item in packs_orders" :key="item.id">
                      <div class="ord_wrapper">
                          <div class="text">
                            <div><b>{{item.pack_name}} </b>  ניצול: מה {{item.uses}}\{{item.num_apt}}</div>
                            <span>{{item.created_date}} </span>
                            <a v-if="item.invoice && item.invoice !=''" :href="item.invoice" target="_blank" class="btn">קבלה</a>
                          </div>
                      </div>
                  </li>
              </ul>
            </div>
            <div v-if="dets_orders && !isEmpty(dets_orders)" class="panel">
              <h2>{{get_word('my_last_dedts')}} </h2>
              <ul class="box">
                  <li v-for="item in dets_orders" :key="item.id">
                      <div class="ord_wrapper">
                          <div class="text">
                            <div><b>{{item.amount}}₪ </b> ל{{item.mikve_data.city}} - {{item.mikve_data.address}}</div>
                            <span>{{item.date_created}} </span>
                            <a v-if="item.invoice && item.invoice !=''" :href="item.invoice" target="_blank" class="btn">קבלה</a>
                          </div>
                      </div>
                  </li>
              </ul>
            </div>
            </div>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    components:{},

    data() {
      return {
        orders: [],
        dets_orders: [],
        packs_orders: [],
        loaded: false,
        
      
      }
    },
    methods: {
    
    },
    mounted() {
     
   

      this.api({ action: 'get_last_orders'}, (data)=>{
        this.orders = data.data.orders;
        this.dets_orders = data.data.dets_orders;
        this.packs_orders = data.data.packs_orders;

         this.loaded = true;
      })

    },
    computed: {
      

      
    },
  }
  </script>
  
<style lang="scss" scoped>
  .btn{font-size: 16px;font-weight: 700;}
  .panel{margin: 30px 0;}
  h2{margin-bottom: 10px;}
  .paddme{padding: 10px 10px !important ;}
  ul{ display: flex;flex-direction: column;gap: 10px; 
    li{margin: unset;border-radius: var(--radius);border-bottom:none !important}
  }
</style>