<template>
    <div id="debt">
        <template  v-if="!show_payment">
        <h2 class="page_title" v-html="get_word('debt_page')"></h2>
        <div class="line" >
            <i class="fas fa-map-marker-alt"></i>
            <div class="content">
                <div class="fav_mikve">
                    <h2 class="mikve_title" v-html="mikve_title"></h2>
                    <div style=" display: flex; gap:10px;justify-content: space-between;">
                        <button class="btn" v-html="get_word('change')" @click="isChangeMikve = true; "></button>
                    </div>
                </div>
                <mikveList v-if="isChangeMikve" @close="isChangeMikve = false" v-model:mikveId="selectedMikve" v-model:mikveName="mikveName" />
                <div class="amount_wrapper">
                    <h2  v-html="get_word('debt_amount')"></h2>
                    <input inputmode="numeric" type="number" v-model="amount"/>
                </div>
                   <div class="btns_wrapper">
                    <!-- <div>
                        <button class="btn" @click="order('bit')">{{ get_word('pay_bit') }} <img src="../assets/Bit_logo.svg" style="height:20px" alt="" /></button>
                    </div> -->
                    
                    <div>
                        <button class="btn" @click="order('payme')">{{ get_word('pay_credit') }} <i class="far fa-credit-card"></i></button>
                    </div>
                </div>
            </div>
      </div>
        </template>
        <template v-if="show_payment">
            <h1>תשלום</h1>
             <payment :paymentData="paymentData" @payment_success="handlePaymentEvent"  @fail_msg="failMsg" @fail="fail()" @cancel="show_payment  = false;" />
        </template>
    </div>
</template>

<script>
import mikveList from '@/views/mikveList.vue';
import payment from "@/components/payment.vue";

export default {
    components:{mikveList , payment},
    data(){
        return {
            show_payment: false,
            items: [],
            paymentData: {},
            mikveName:'',
            isChangeMikve:false,
            selectedMikve:0,
            amount:'',
            apt_loading:false,
            paymentId:0
        }
    },
    mounted() {

         this.mikveName = this.isNull(this.$store.user.mikve) ? '' : this.$store.user.mikve.name;
         this.selectedMikve = this.isNull(this.$store.user.mikve) ? '' : this.$store.user.mikve.id;

       

    },
    computed:{
         mikve_title() {
          return this.get_word('mikve_debt_title')+'<div>'+this.mikveName + '</div>';
        }
    },
    methods:{
         failMsg(){
            this.alert(this.get_word('cant_create_payment'));
            this.show_payment = false;
        },
        handlePaymentEvent()
        {
            this.alert('התשלום בוצע בהצלחה');
            this.$bus.$emit('reload');
        },
        order(provider = 'payme'){
            if(this.amount <= 4){
                this.alert(this.get_word("under_5"));
                return false;
            }
            
            this.api({ action: 'add_debt_ord', data: {provider:provider,mikve_id:this.selectedMikve,amount:this.amount}}, (data) => {
                    if (data.data)
                    {
                        this.paymentData = {
                            name: data.data.name,
                            price: +data.data.amount + '₪',
                            id:data.data.id,
                        };
                        this.show_payment = true;
                    }
                    else {
                      this.alert(this.get_word("no_payments"));
                    }
                  
            });
        }
    }
}

</script>

<style lang="scss" scoped>
#debt{padding: 0 10px;}
.page_title{font-weight: 700;text-align: center;font-size: 30px;}
.amount_wrapper{margin: 20px 0;}
   .btns_wrapper{
        .svg-inline--fa {    font-size: 19px;}
        &{flex-basis: 100%;display: flex;justify-content: center;gap: 5px;}
        div{flex: 1;
            button{    display: flex;align-items: center; font-size: 14px; padding: 0 13px; width: 100%; line-height: 1; align-content: center; height: 65px; flex-direction: column-reverse; padding-top: 10px;border-radius: 10px;}
        }
    }
.fav_mikve{display: flex;justify-content: space-between;padding: 0 10px;color: #000;}
.line{border-bottom: none;}

</style>