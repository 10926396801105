<template>
<div id="layout" v-if="loaded">
  <div id="mikve_list" :class="{ box: true, active: isActive}" >
    <a id="close" @click="close"><i class="fas fa-times"></i></a>
    <h1 v-text="get_word('mikve_list_title')"></h1>
    <div id="seacrh_wrapper">
       <input type="text" v-model="name" :placeholder="get_word('search_mikve')" ref="searchInput"/>
    </div>
    <div id="results">
        <template v-if="results && results.length > 0">
            <div v-for="mikve in results" :key="mikve.id">
                <div :class="'mikve_wrapper '+(mikve.open ? 'open' : 'close')" >
                    <div class="upper">
                        <div class="name" v-text="mikve.text" @click="mikve.open = !mikve.open"></div>
                        <button class="btn" @click="set(mikve)" >{{get_word('choose')}}</button>
                    </div>
                    <a @click="mikve.open = !mikve.open" class="icon"><i class="fa-solid fa-chevron-down" ></i></a>
                    <ul v-if="mikve.open" class="details">
                        <li class="data" v-if="mikve.phone">
                            <label v-text="get_word('mikve_phone')"></label> 
                            <span v-text="mikve.phone"></span>
                        </li>
                        <li class="data" v-if="mikve.hours">
                            <label v-text="get_word('mikve_hours')"></label> 
                            <span v-text="mikve.hours"></span>
                        </li>
                        <li class="data"  v-if="mikve.description">
                            <label v-text="get_word('mikve_description')"></label> 
                            <span v-text="mikve.description"></span>
                        </li>
                         <li v-if="mikve.workers_today">
                            <label v-text="get_word('workers')"></label>
                            <div  v-for="(work,i) in mikve.workers_today" :key="i">{{work}}</div>
                        </li>
                        <li class="data" v-if="mikve.accessibility && mikve.accessibility != '' ">
                            <label v-text="get_word('mikve_accessibility')"></label> 
                            <span v-text="mikve.accessibility"></span>
                        </li>
                        <li class="data"  v-if="mikve.capacity">
                            <label v-text="get_word('mikve_capacity')"></label> 
                            <span v-text="mikve.capacity"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <div v-else>
          הקלידי שם של עיר או שם של מקווה לחיפוש
        </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
    props: ["mikveId", "mikveName"],
    emits: ['update:mikveId', 'update:mikveName'],
    data() {
        return {
            isFocus: false,
            loaded: false,
            isActive: false,
            searchTimeout: null,
            results:[],
            latitude:'',
            longitude:'',
            name:''  
        }
    },
    mounted(){
        
        // this.getLocation();
        this.loadData();
        setTimeout(()=>{
             this.isActive = true;
        },150);

       
    },
    computed: {
     
    },
     watch: {
        name() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);  // Clear the existing timer if the user continues typing
        }
        this.searchTimeout = setTimeout(() => {
            this.loadData();  // Call loadData after the user has stopped typing for 500 ms
        }, 300);  // You can adjust the delay here
        }
    },
    methods:{    
        getLocation() {
            if (navigator.geolocation) {
               navigator.geolocation.getCurrentPosition(this.showPosition);
            }
            else{
                return true;
            }
        },
        set(mikve) {
            this.$emit('update:mikveId', mikve.id);
            this.$emit('update:mikveName', mikve.text);
            this.$emit('change');
            
            this.close();
        },
        close(){
             this.isActive = false;
            setTimeout(()=>{
                this.$emit('close');
            },100);
        },

        showPosition(position) {
            if(position && position.coords && position.coords.latitude !="" && position.coords.longitude !="")
            {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
            }
            return true;
        },
       
        loadData(){  
            const latitude = this.latitude;
            const longitude = this.longitude;
            this.api({action: 'get_mikves', data: { name:this.name ,latitude,longitude} }, (data)=>{
                for(let i in data.data.items)
                {
                    data.data.items[i].open = false;
                }
                this.results = data.data.items;
                this.loaded = true;
                if(this.$refs["searchInput"] != '' && !this.isFocus)
                {
                    this.$nextTick(() => {
                        this.$refs["searchInput"].focus();
                        this.isFocus = true;
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
#close { position: absolute; right: 0px; top: 0px; width:40px; height: 40px; display: flex; justify-content: center; align-items: center;}
#layout{background: rgba(0, 0, 0, 0.2);position: fixed;inset: 0;z-index: 9999;}
#mikve_list{ position: absolute; display: flex; flex-direction: column; height: calc(100vh - 190px); padding-bottom: 0px; top:50%;
    max-width: 920px;
    background-color: #fff;
    left: 50%; overflow: hidden;border-radius: 10px;max-width: 800px;width: 95%;
  animation-duration: 0.5s; transform: translate(-50%, -50%) scale(1.15); transition: transform 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946); will-change: transform, opacity;
    opacity:0;
    &.active{transform: translate(-50%, -50%) scale(1); opacity: 1; transition: transform 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946), opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946); }
    h1{text-align: center;}
    #seacrh_wrapper { margin-bottom: 20px;}
    #results{ overflow-y: scroll;height: 100%; margin: 0 -20px; padding: 0 20px; padding-bottom: 20px;
        .mikve_wrapper{box-shadow: 0px 0px 37px rgba(0, 0, 0, 0.1607843137);position: relative;border-radius: 10px;
        background: #fff;margin: 20px 0;padding: 10px;transition: all 0.6s ease; overflow: hidden;max-height:100px;
        &.open{ max-height:500px;transition: all 0.6s ease}
        .upper{display: flex;justify-content: space-between;align-items: center;padding-left: 35px; gap:8px; }
        .icon{position: absolute;left: 10px;top: 25px; transition: all 0.2s ease; transform: rotate(0deg);   z-index: 99; }
        &.open .icon{transform: rotate(180deg);}
        }
        
    }  
    .details { list-style: none; 
        li {
            label {padding-left: 15px;; font-weight: bold;
                &::after { content: ":"; }
            }
            span { font-weight: 400;}
        }
    } 
}
</style>