<template>
  <div id="page" :class="'rtl ' + (show_side_menu ? 'menu_open' : '') + ' ' + (user && user.is_subscribed ? 'is_vip' : '')">

    <!-- <search v-if="is_search_opened" @closesearch="is_search_opened = false" /> -->
    <div id="side_menu">

      <div id="menu_logo"></div>
        
      <ul>
        <li v-for="menu_item in menu" :key="menu_item.id">
          <router-link v-on:click="show_side_menu=false" :to="menu_item.link" >{{menu_item.name}}</router-link>
        </li>
        <li ><router-link @click="show_side_menu=false" to="/lastorders">הזמנות אחרונות</router-link></li>
        <li ><router-link @click="show_side_menu=false" to="/contact">יצירת קשר</router-link></li>

        <li v-if="get_me()"><a @click="show_side_menu=false; logout()">יציאה</a></li>
      </ul>


    </div>

    <main id="page" v-if="loaded"> 

      <div id="menu_hamburger" v-on:click="show_side_menu=!show_side_menu">
        <div id="nav-icon3"><span></span><span></span><span></span><span></span></div>
      </div>

      <Header :user="user" :class="header_class" />
      <div id="content">
   
        <LoginPage v-if="show_login" />   
        <template v-else>
 
          <RequirdDetails v-if="!show_login && show_settings.length > 0" :RequirdDetails="show_settings" @refresh_data="refresh_data"/>
          <router-view v-else v-slot="{ Component }" @loaded="is_show_loader=false;" @show_loader="is_show_loader=true;">
            <transition name="fade">
              <component :is="Component" :key="$route.path" />
            </transition>
          </router-view>
        </template>
      </div>



    </main>
    
    <div id="loading_animation" v-if="is_show_loader || !loaded"><div id="spinner_ajax"></div></div>
  </div>
</template>

<script>

import Header from '@/components/global/header.vue'
import LoginPage from '@/views/login.vue'
import RequirdDetails from '@/views/RequirdDetails.vue'
// import search from './components/global/search.vue'


export default {
  name: 'app',
  components: { Header, LoginPage,RequirdDetails },
  data(){
    return {
      loaded: false,
      show_login: false,
      showUpdate: false,
      show_settings: [],
      menu: [],
      banner:null,
      androidVersion:0,
      currVersion:0,
      iosVersion:0,
      androidStoreLink:'https://play.google.com/store/apps/details?id=mikve.online.com&hl=he_IL',
      header_class: '',
      footer_menu: {},
      foot_line: {},
      show_side_menu: false,
      show_splash_screen: false,
      is_show_loader: false,
      is_search_opened: false,
      show_banner_screen: true,
      pageViewsCounter: 0,
      user: false
    }
    
  },
  mounted() { 
    window.appVue = this;
    this.$bus.$on('reload', (loadData, openURL) => { this.reload(loadData, openURL); });

    this.reloadPage();
  },
  methods:{
    promptForUpdate(platform)
    {
      console.log(platform);
      //  this.confirm(this.get_word('updateVersion'), this.get_word('ok_update')).then((result) => {
      //       if (result) {
      //          alert('update');
      //       }
      //   });
    },
    compareVersions()
    {
        if(this.isIOS)
        {
          console.log('ios');
        }
        else
        {
          setTimeout(() => {

          cordova.plugins.InAppUpdate.updateFlexible(function(success){
            console.log(JSON.stringify(success));
          }, function(error){
              console.log(JSON.stringify(error));
          });

          }, 5000);
        }
    },
    openLink(url) {
      setTimeout(() => {
        alert(1);
        console.log(url);
           cordova.plugins.InAppUpdate.updateImmediate(function(success){
              alert(JSON.stringify(success));
          }, function(error){
              alert(JSON.stringify(error));
          });



      }, 5000);
     
    },
 
    refresh_data(){
      this.reloadPage();
    },
    reload(loadData, openURL){
      this.loaded=false;

      if (this.isNull(openURL)) openURL = '/';

      if (this.$route.path !== '/') this.$router.push(openURL);
      //this.refreshKey++;
      if (loadData !== false) this.reloadPage();
      else this.loaded = true;
    },
    reloadPage(){

      let getVars = {};
      let uri = window.location.href.split('?');
      if(uri.length == 2) {
        let vars = uri[1].split('&');
        
        let tmp = '';
        vars.forEach(function(v) {
          tmp = v.split('=');
          if(tmp.length == 2)
            getVars[tmp[0]] = tmp[1];
        });
        // do 
      }

      if(!this.isNull(getVars.token))
      {
        this.set_user_token(getVars.token);
        document.location = '/'
      }

      // categories
      this.api({ action: 'general', method: 'get'}, (data) => { 

        this.menu = data.data.menu;

        this.footer_menu = data.data.footer_menu;
        this.show_settings = data.data.show_settings;
        //this.foot_line = data.data.foot_line;
        this.$store.words = data.data.words;
        this.currVersion = this.$store.state.androidVersion;
        this.androidVersion = data.data.android_version;
        this.iosVersion = data.data.ios_version;
        if(this.isApp)
        {
          this.compareVersions();
        }
          
        this.$store.unit_types = data.data.unit_types;
        
        if(!localStorage.getItem("app_version") || !(localStorage.getItem("app_version") > 0 ) || localStorage.getItem("app_version") != data.data.version)
        {
          localStorage.setItem("app_version",data.data.version);
          location.reload(true);
        }

        this.$store.user = data.data.user;
        this.user = data.data.user;

        this.$store.order_types = [
          {id: 1, name: 'טבילה רגילה / כלה בשנה הראשונה'},
          {id: 2, name: 'טבילה עם הכנה ומגבת / כלה בשנה הראשונה '},
          {id: 3, name: 'טבילת כלה / חריגה'}
        ];    

        // if (this.user.is_bride_valid && !this.user.bride_already_orderded) this.$store.order_types.push({id: 3, name: this.get_word('bride_type_order')});


        this.show_login = false;
        if (!this.get_me()) this.show_login = true;

      

        this.loaded = true;
        
      });
    }
  }
}
</script>